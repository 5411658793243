@import "~bootstrap/scss/bootstrap-grid.scss";

.write-us__wrapper {
  background: var(--primary);
  color: #ffffff;
  border-radius: 15px;
  height: 180px;
  display: flex;
  justify-content: center;
  padding: 1.5rem;
  @include media-breakpoint-down(lg) {
    height: 120px;
    padding: 1.2rem;
  }
  .write-us__info {
    max-width: 650px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    h4 {
      line-height: 1.5;
      @include media-breakpoint-down(lg) {
        font-size: 14px;
      }
    }
  }
}

.course-img {
  width: 100%;
  height: 350px;
  object-fit: cover;
}

.course-detailed__ul {
  list-style: none;
  padding: 0;
  li {
    &:not(:last-child) {
      margin-right: 2rem;
      @include media-breakpoint-down(lg) {
        margin-right: 0.5rem;
      }
    }
    a {
      text-decoration: none;
      img {
        width: 15px;
        margin-right: 0.5rem;
      }
    }
  }
}

.course-table {
  .video-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    span {
      margin-left: 0.5rem;
      margin-right: 1rem;
    }
    .video-icon {
      width: 20px;
    }
  }
  &.table {
    td,
    th {
      padding-left: 1rem;
      padding-right: 1rem;
      &:nth-child(2) {
        width: 20%;
      }
    }
  }
}

.student-feedback__wrapper {
  .student-feedback__info {
    display: flex;
    .student-feedback__rating {
      background: #01cc83;
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5rem;
      @include media-breakpoint-down(lg) {
        padding: 1rem;
      }
      @include media-breakpoint-down(md) {
        padding: 0.5rem;
      }
    }
  }
  .student-feedback__data {
    font-size: 14px;
    padding: 0 2.5rem;
    @include media-breakpoint-down(lg) {
      font-size: 12px;
      padding: 0 1rem;
    }
    .user-rating {
      display: flex;
      align-items: center;
      img {
        @include media-breakpoint-down(md) {
          width: 40px;
        }
      }
    }
    .star {
      color: #ffaa2a;
      font-size: 18px;
      @include media-breakpoint-down(md) {
        font-size: 12px;
      }
    }
    .progress {
      width: 175px;
      height: 5px;
      @include media-breakpoint-down(md) {
        width: 80px;
      }
    }
  }
}

.course-more-info__wrapper {
  box-shadow: 0px 0px 15px 0px #00000014;
  padding: 1rem;
  .course-para-table {
    font-size: 14px;
    i {
      color: var(--primary);
      font-size: 20px;
      margin-right: 0.25rem;
    }
    td:nth-child(2) {
      width: 40%;
      text-align: right;
      font-weight: 600;
    }
  }
}

.related-course__img {
  width: 100px;
}

.price-tag {
  color: #ffffff;
  font-weight: 600;
  font-size: 20px;
  background-color: rgba(var(--bs-success-rgb)) !important;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 1rem;
  top: -2rem;
}

.my-course__card {
  &.card {
    background: url("../../assets/img/detailed-course.svg") top center/100% no-repeat;
    border: none;
    border-radius: 0;
    .card-header {
      border: none;
    }
    .card-body {
      border-radius: 15px;
      box-shadow: 0px 7px 35px 0px rgba(0, 0, 0, 0.1);
      background: #fff;
      margin: 1rem;
      margin-top: 5rem;
      padding-bottom: 0;
    }
  }
}

.module-table {
  &.table {
    border-radius: 10px;
    margin: 0;
    thead th {
      background: rgba(247, 248, 251, 1);
      color: var(--primary);
    }
    th,
    td {
      vertical-align: middle;
      padding: 0.75rem 1rem;
      &:last-child {
        width: 130px;
      }
    }
    tr {
      &:last-child {
        td {
          border: 0;
        }
      }
    }
  }
}

.card {
  &.module-card {
    overflow: auto;
    .card-body {
      background: rgba(247, 248, 251, 1);
    }
  }
}
